import { defaultTheme } from "react-admin";
import { createMuiTheme } from '@material-ui/core/styles';

export const customTheme = createMuiTheme({
  ...defaultTheme,
  ...{
    palette: {
      primary: {
        main: "#7edcff",
      },
      secondary: {
        main: process.env.REACT_APP_PRIMARY_COLOR,
      },
    }
  }
});