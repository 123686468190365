import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

import { TextInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';

export const ServicesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="schedule" />
            <FieldGuesser source="phone" />
            <FieldGuesser source="phone_2" />
            <FieldGuesser source="phone_3" />
            <FieldGuesser source="information" />
            <FieldGuesser source="secondary_information" />
            <FieldGuesser source="priority" />
        </ListGuesser>
    </div>

);

export const ServiceCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <TextInput multiline={true} source="schedule"/>
        <InputGuesser source="phone" />
        <InputGuesser source="phone_2" />
        <InputGuesser source="phone_3" />
        <InputGuesser source="address" />
        <InputGuesser source="place" />
        <InputGuesser source="priority" />
        <InputGuesser source="town" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <TextInput multiline={true} source="information"/>
        <TextInput multiline={true} source="secondary_information"/>
    </CreateGuesser>
);

export const ServiceEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <TextInput multiline={true} source="schedule"/>
        <InputGuesser source="phone" />
        <InputGuesser source="phone_2" />
        <InputGuesser source="phone_3" />
        <InputGuesser source="address" />
        <InputGuesser source="place" />
        <InputGuesser source="priority" />
        <InputGuesser source="town" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <TextInput multiline={true} source="information"/>
        <TextInput multiline={true} source="secondary_information"/>
        <ReferenceInput
            source="image"
            reference="media_objects"
            label="Image"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
