import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';
import { AutocompleteInput } from 'react-admin';

const types = [
    { name: "SUGGESTION", cname: "suggestion", value: "suggestion" },
    { name: "OTHER", cname: 'other', value: "other" },
];

const status = [
    { name: "PENDING", cname: "pending", value: "pending" },
    { name: "ANSWERED", cname: 'answered', value: "answered" },
    { name: "IGNORED", cname: 'ignored', value: "ignored" },
    { name: "RESENT", cname: 'resent', value: "resent" },
];
export const ContactFormsList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="last_name" />
            <FieldGuesser source="email" />
            <FieldGuesser source="type" />
            <FieldGuesser source="status" />
        </ListGuesser>
    </div>

);

export const ContactFormCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="last_name" />
        <InputGuesser source="email" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <InputGuesser source="message"/>
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />
    </CreateGuesser>
);

export const ContactFormEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="last_name" />
        <InputGuesser source="email" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <InputGuesser source="message"/>
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />
    </EditGuesser>
);
