import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

import { TextInput, DateTimeInput, ReferenceInput, AutocompleteInput, BooleanInput, FunctionField } from 'react-admin';

export const PicOfTheDaysList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="title" />
            <FieldGuesser source="author" />
            <FieldGuesser source="date" />
            <FieldGuesser source="active" />
        </ListGuesser>
    </div>

);

export const PicOfTheDayCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="author" />
        <DateTimeInput source="date" />
        <BooleanInput source="active" />
    </CreateGuesser>
);

export const PicOfTheDayEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="author" />
        <DateTimeInput source="date" />
        <BooleanInput source="active" />
        <ReferenceInput
            source="image"
            reference="media_objects"
            label="Image"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
