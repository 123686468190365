import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
    dataProvider as baseDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser  ,
} from '@api-platform/admin';
import authProvider from './authProvider';
import { Navigate } from 'react-router-dom';

import Dashboard from './Dashboard';

import {UsersList, UsersCreate, UserEdit } from './Users';
import {MediaObjectsCreate, MediaObjectsList} from "./MediaObjects";
import {BusinessList, BusinessCreate, BusinessEdit, BusinessShow} from "./Business";
import {EventsCreate, EventsEdit, EventsList} from "./Events";
import {PicOfTheDayCreate, PicOfTheDayEdit, PicOfTheDaysList} from "./PicOfTheDay";
import {ServiceCreate, ServiceEdit, ServicesList} from "./Services";
import {NotificationMessagesList, NotificationMessageCreate, NotificationMessageEdit} from "./NotificationMessages";
import {ContactFormEdit, ContactFormCreate, ContactFormsList} from "./ContactForms";
import {customTheme} from "./CustomTheme";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Navigate to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser);


export default props => (
    <HydraAdmin
        apiDocumentationParser={ apiDocumentationParser }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        dashboard={Dashboard}
        theme={customTheme}
    >
        <ResourceGuesser name="users" create={UsersCreate} list={UsersList} edit={UserEdit}/>
        <ResourceGuesser name="businesses" create={BusinessCreate} list={BusinessList} edit={BusinessEdit} show={BusinessShow}/>
        <ResourceGuesser name="businesses" create={BusinessCreate} list={BusinessList} edit={BusinessEdit}/>
        <ResourceGuesser name="events" create={EventsCreate} list={EventsList} edit={EventsEdit} />
        <ResourceGuesser name="services" create={ServiceCreate} list={ServicesList} edit={ServiceEdit} />
        <ResourceGuesser name="pic_of_the_days" create={PicOfTheDayCreate} list={PicOfTheDaysList} edit={PicOfTheDayEdit} />
        <ResourceGuesser name="notification_messages" create={NotificationMessageCreate} list={NotificationMessagesList} edit={NotificationMessageEdit} />
        <ResourceGuesser name="media_objects" create={MediaObjectsCreate} list={MediaObjectsList}/>
        <ResourceGuesser name="contact_forms" create={ContactFormCreate} list={ContactFormsList} edit={ContactFormEdit} />
    </HydraAdmin>

);
