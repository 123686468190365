import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

import { TextInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';

export const EventsList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="title" />
            <FieldGuesser source="excerpt" />
            <FieldGuesser source="date" />
            <FieldGuesser source="place" />
            <FieldGuesser source="external_link" />
        </ListGuesser>
    </div>

);

export const EventsCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="title" />
        <TextInput multiline={true} source="description"/>
        <TextInput multiline={true} source="excerpt"/>
        <DateTimeInput source="date" />
        <InputGuesser source="place" />
        <InputGuesser source="address" />
        <InputGuesser source="town" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <InputGuesser source="external_link" />
    </CreateGuesser>
);

export const EventsEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="title" />
        <TextInput multiline={true} source="description"/>
        <TextInput multiline={true} source="excerpt"/>
        <DateTimeInput source="date" />
        <InputGuesser source="place" />
        <InputGuesser source="address" />
        <InputGuesser source="town" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <InputGuesser source="external_link" />
        <ReferenceInput
            source="image"
            reference="media_objects"
            label="Image"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
