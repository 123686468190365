import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
    ShowGuesser,
} from '@api-platform/admin';

import {FunctionField} from 'react-admin';


import { TextInput, ReferenceInput, AutocompleteInput  } from 'react-admin';

export const BusinessList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="short_description" />
            <FieldGuesser source="phone" />
            <FieldGuesser source="address" />
            <FieldGuesser source="priority" />
        </ListGuesser>
    </div>

);

export const BusinessShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"description"} addLabel={true} />
        <FieldGuesser source={"short_description"} addLabel={true} />
        <FieldGuesser source={"phone"} addLabel={true} />
        <FieldGuesser source={"address"} addLabel={true} />
        <FieldGuesser source={"internal_note"} addLabel={true} />
        <FieldGuesser source={"latitude"} addLabel={true} />
        <FieldGuesser source={"longitude"} addLabel={true} />
        <FieldGuesser source={"place"} addLabel={true} />
        <FieldGuesser source={"town"} addLabel={true} />
        <FieldGuesser source={"priority"} addLabel={true} />
        <FieldGuesser source={"created_at"} addLabel={true} />
        <FieldGuesser source={"updated_at"} addLabel={true} />
        <FieldGuesser source={"image"} addLabel={true} />
        <FieldGuesser source={"logo"} addLabel={true} />
    </ShowGuesser>
);

export const BusinessCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="phone" />
        <InputGuesser source="address" />
        <InputGuesser source="place" />
        <InputGuesser source="priority" />
        <InputGuesser source="town" />
        <TextInput multiline={true} source="description"/>
        <TextInput multiline={true} source="short_description"/>
        <InputGuesser source="internal_note" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
    </CreateGuesser>
);

export const BusinessEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="phone" />
        <InputGuesser source="address" />
        <InputGuesser source="place" />
        <InputGuesser source="priority" />
        <InputGuesser source="town" />
        <TextInput multiline={true} source="description"/>
        <TextInput multiline={true} source="short_description"/>
        <InputGuesser source="internal_note" />
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <ReferenceInput
            source="image"
            reference="media_objects"
            label="Image"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="logo"
            reference="media_objects"
            label="Logo"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
