import React from 'react';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

import {DateTimeInput, AutocompleteInput  } from 'react-admin';

const status = [
    { name: "draft", cname: "draft", value: "draft" },
    { name: "ready", cname: "ready", value: "ready" },
];

const topics = [
    { name: "asf", cname: "asf", value: "asf" },
    { name: "aguasalmarda", cname: "aguasalmarda", value: "aguasalmarda" },
];

export const NotificationMessagesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="title" />
            <FieldGuesser source="body" />
            <FieldGuesser source="topic" />
            <FieldGuesser source="status" />
            <FieldGuesser source="publish_at" />
        </ListGuesser>
    </div>

);

export const NotificationMessageCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="body" />
        <AutocompleteInput
            source="topic"
            choices={topics}
            optionText="cname"
            optionValue="value"
        />
        <DateTimeInput source="publish_at" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />
    </CreateGuesser>
);

export const NotificationMessageEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="title" />
        <InputGuesser source="body" />
        <AutocompleteInput
            source="topic"
            choices={topics}
            optionText="cname"
            optionValue="value"
        />
        <DateTimeInput source="publish_at" />
        <AutocompleteInput
            source="status"
            choices={status}
            optionText="cname"
            optionValue="value"
        />

    </EditGuesser>
);
